<template>
  <div>
    <div v-if="$store.state.avatars.length" class="playerAvatar placeholder" :style="{
      width: `${scale * 20}px`,
      height: `${scale * 20}px`,
    }">
      <div class="inner">
        <span class="questionmark">?</span>
      </div>
    </div>
    <div class="username">USERNAME</div>
  </div>
</template>

<script>
export default {
  props: {
    scale: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>

</style>